// deployed

export default {
host: 'repa8nuc00b2vcz.eu.qlikcloud.com',
secure: true,
port: null,
prefix: '',
appId: '3bfcda28-8f2f-48b9-8e94-6bd7e03377d4',
webIntId: '9ChazEO02jkNEjOet9CEaHcEeE-_BcDP',
qcs: true,
};
