import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fText } from './formatText';
import { fPercentVar, fValueVolume, fPercent, fPercentPointVar, fOneDecimalPlace } from './formatNumber';
import Label from '../components/Label';

// ----------------------------------------------------------------------

const ThumbImgStyle = styled('img')(({ theme }) => ({
  width: 74,
  height: 74,
  objectFit: 'cover',
  margin: theme.spacing(0, 0),
  marginRight: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const cover = 'https://d1j9x9sbg3f3rb.cloudfront.net/standard_no_image.jpg';

export function fQlikTableCell({ text, number, columnId }, dataIndex, isMetric) {
  if (text.includes('SPLIT')) {
    const splitValues = text.split('SPLIT');

    return (
      <>
        <Box
          sx={{
            py: 2,
            pl: 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ThumbImgStyle alt="test" src={splitValues[3] ? splitValues[3] : cover} />

          <Box
            sx={{
              py: 2,
              display: 'block',
              // overflowWrap: 'anywhere',
            }}
          >
            <Typography component="div" variant="body3">
              {splitValues[1]}
            </Typography>

            <Typography component="div" variant="body3">
              {splitValues[2]}
            </Typography>

            <Typography component="div" variant="body3" sx={{ color: '#637381', overflowWrap: 'anywhere' }}>
              {splitValues[0]}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  if (number === 'NaN' || columnId === 0 || dataIndex.toLowerCase().includes('first sale')) {
    return fText(text);
  }

  if (dataIndex.toLowerCase().includes('value')) {
    return fValueVolume(number, true);
  }

  if (dataIndex.toLowerCase().includes('mix')) {
    return fPercent(number);
  }

  if (dataIndex.toLowerCase().includes('volume')) {
    return fValueVolume(number, false);
  }

  let displayNumber;

  if (dataIndex.includes('%')) {
    displayNumber = fPercentVar(number);
  } else if (dataIndex.includes('PP')) {
    displayNumber = fPercentPointVar(number);
  } else if (dataIndex.toLowerCase().includes('upt')) {
    displayNumber = fOneDecimalPlace(number);
  } else if (dataIndex.toLowerCase().includes('avt')) {
    displayNumber = fValueVolume(number, true);
  } else {
    displayNumber = fValueVolume(number, isMetric);
  }

  if (dataIndex.includes('vs')) {
    return <Label color={number > 0 ? 'success' : 'error'}>{displayNumber}</Label>;
  }

  if (dataIndex.toLowerCase().includes('upt')) {
    return fOneDecimalPlace(number);
  }

  if (dataIndex.toLowerCase().includes('avt')) {
    return fValueVolume(number, true);
  }

  return displayNumber;
}
