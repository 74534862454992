export const peformance = [
  {
    type: 'kpi',
    title: 'WTD Sales',
    md: 3,
    xs: 12,
    qMetrics: [
      {
        qExpr: '=([WTD]/[WTD Comp Period] - 1)',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=WTD',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: "=if(variance='Last Year','LY','LLY')",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },
  {
    type: 'kpi',
    title: 'MTD Sales',
    md: 3,
    xs: 12,
    qMetrics: [
      {
        qExpr: '=([MTD]/[MTD Comp Period] - 1)',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: 'MTD',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: "=if(variance='Last Year','LY','LLY')",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },
  {
    type: 'kpi',
    title: 'QTD Sales',
    md: 3,
    xs: 12,
    qMetrics: [
      {
        qExpr: '=([QTD]/[QTD Comp Period] - 1)',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: 'QTD',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: "=if(variance='Last Year','LY','LLY')",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },
  {
    type: 'kpi',
    title: 'YTD Sales',
    md: 3,
    xs: 12,
    qMetrics: [
      {
        qExpr: '=([YTD]/[YTD Comp Period] - 1)',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: 'YTD',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: "=if(variance='Last Year','LY','LLY')",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },
  {
    type: 'line',
    title: 'Sales evolution',
    md: 12,
    xs: 12,
    chartConfig: { categoryLabel: 'monthofyear', metrics: ['MTD', 'Comparison'], isDate: false },
    cols: [
      {
        qField: 'month_desc',
        dataKey: 'monthofyear',
        qLabel: 'monthofyear',
      },
      {
        qField: '=Round(YTD)',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },
      {
        qField: '=Round([YTD Comp Period])',
        dataKey: 'Comparison',
        qLabel: 'Comparison',
      },
      {
        qField: "=if(YTD<>0,if(metric='Volume',0,1),3)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: '=only({<year = {$(asOfYear)}> } month_desc)',
        dataKey: 'maxmonthid',
        qLabel: 'maxmonthid',
      },
    ],
  },
  {
    type: 'table',
    title: 'Sales by Sales Type',
    md: 12,
    xs: 12,
    qPage: { qWidth: 30, qHeight: 333 },
    initialSortKey: 'WTD',
    initialColumnsToExclude: [
      'YTDUPT',
      'vsLYYTDUPT',
      'MTDUPT',
      'vsLYMTDUPT',
      'metric',
      'varianceText',
      'WTD Mix',
      'vsWTDPP',
      'MTD Mix',
      'vsMTDPP',
      'YTD Mix',
      'vsYTDPP',
      'WTDUPT',
      'vsLYWTDUPT',
      'MTDAVT',
      'vsLYMTDAVT',
      'YTDAVT',
      'vsLYYTDAVT',
      'WTDAVT',
      'vsLYWTDAVT',
    ],
    options: {
      coreColumns: ['Sales Type'],
      alwaysHide: ['metric', 'varianceText'],
      dropdown: [
        {
          name: 'Actual',
          hideColumns: [
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'vsLYMTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
        {
          name: 'Mix',
          hideColumns: [
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYMTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
          ],
        },
        {
          name: 'Tickets',
          hideColumns: [
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
      ],
    },
    cols: [
      {
        qField: '[Sales Type]',
        dataKey: 'Sales Type',
        qLabel: 'Sales Type',
      },
      {
        qField: '=WTD',
        dataKey: 'WTD',
        qLabel: 'WTD',
      },
      {
        qField: '=[WTD]-[WTD Comp Period]',
        dataKey: 'vs LYWTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([WTD]/[WTD Comp Period]) - 1',
        dataKey: 'vs LYWTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[WTD Mix]',
        dataKey: 'WTD Mix',
        qLabel: 'WTD Mix',
      },
      {
        qField: '=[WTD Mix] - [WTD Comparable Mix]',
        dataKey: 'vsWTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD]',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },
      {
        qField: '=[MTD]-[MTD Comp Period]',
        dataKey: 'vs LYMTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([MTD]/[MTD Comp Period]) - 1',
        dataKey: 'vs LYMTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD Mix]',
        dataKey: 'MTD Mix',
        qLabel: 'MTD Mix',
      },
      {
        qField: '=[MTD Mix] - [MTD Comparable Mix]',
        dataKey: 'vsMTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD]',
        dataKey: 'YTD',
        qLabel: 'YTD',
      },
      {
        qField: '=[YTD]-[YTD Comp Period]',
        dataKey: 'vs LYYTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([YTD]/[YTD Comp Period]) - 1',
        dataKey: 'vs LYYTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD Mix]',
        dataKey: 'YTD Mix',
        qLabel: 'YTD Mix',
      },
      {
        qField: '=[YTD Mix] - [YTD Comparable Mix]',
        dataKey: 'vsYTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: "=if(metric='Volume',0,1)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: "=if(variance='Last Year','vs LY','vs LLY')",
        dataKey: 'varianceText',
        qLabel: 'varianceText',
      },
      {
        qField: '=[WTD UPT]',
        dataKey: 'WTDUPT',
        qLabel: 'WTD UPT',
      },
      {
        qField: '=[WTD UPT] - [WTD UPT Comparable]',
        dataKey: 'vsLYWTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[WTD AVT]',
        dataKey: 'WTDAVT',
        qLabel: 'WTD AVT',
      },
      {
        qField: '=[WTD AVT]-[WTD AVT Comparable]',
        dataKey: 'vsLYWTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD UPT]',
        dataKey: 'MTDUPT',
        qLabel: 'MTD UPT',
      },
      {
        qField: '=[MTD UPT] - [MTD UPT Comparable]',
        dataKey: 'vsLYMTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD AVT]',
        dataKey: 'MTDAVT',
        qLabel: 'MTD AVT',
      },
      {
        qField: '=[MTD AVT]-[MTD AVT Comparable]',
        dataKey: 'vsLYMTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD UPT]',
        dataKey: 'YTDUPT',
        qLabel: 'YTD UPT',
      },
      {
        qField: '=[YTD UPT] - [YTD UPT Comparable]',
        dataKey: 'vsLYYTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD AVT]',
        dataKey: 'YTDAVT',
        qLabel: 'YTD AVT',
      },
      {
        qField: '=[YTD AVT]-[YTD AVT Comparable]',
        dataKey: 'vsLYYTDAVT',
        qLabel: 'vs LY',
      },
    ],
  },
  {
    type: 'table',
    title: 'Sales by Department',
    md: 12,
    xs: 12,
    qPage: { qWidth: 30, qHeight: 333 },
    initialSortKey: 'WTD',
    initialColumnsToExclude: [
      'metric',
      'varianceText',
      'WTD Mix',
      'vsWTDPP',
      'MTD Mix',
      'vsMTDPP',
      'YTD Mix',
      'vsYTDPP',
      'WTDAVT',
      'vsLYWTDAVT',
      'MTDAVT',
      'vsLYMTDAVT',
      'YTDAVT',
      'vsLYYTDAVT',
      'YTDUPT',
      'vsLYYTDUPT',
      'MTDUPT',
      'vsLYWTDUPT',
      'WTDUPT',
      'vsLYMTDUPT',
    ],
    options: {
      coreColumns: ['department_desc'],
      alwaysHide: ['metric', 'varianceText'],
      dropdown: [
        {
          name: 'Actual',
          hideColumns: [
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'vsLYMTDUPT',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
        {
          name: 'Mix',
          hideColumns: [
            'vsLYMTDUPT',
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'vsLYMTDUPT',
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
          ],
        },
        {
          name: 'Tickets',
          hideColumns: [
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
      ],
    },
    cols: [
      {
        qField: ['[department_desc]', '[sub_department_desc]'],
        dataKey: 'department_desc',
        qLabel: 'department_desc',
        qGrouping: 'H',
      },
      {
        qField: '=WTD',
        dataKey: 'WTD',
        qLabel: 'WTD',
      },
      {
        qField: '=[WTD]-[WTD Comp Period]',
        dataKey: 'vs LYWTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([WTD]/[WTD Comp Period]) - 1',
        dataKey: 'vs LYWTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[WTD Mix]',
        dataKey: 'WTD Mix',
        qLabel: 'WTD Mix',
      },
      {
        qField: '=[WTD Mix] - [WTD Comparable Mix]',
        dataKey: 'vsWTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD]',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },

      {
        qField: '=[MTD]-[MTD Comp Period]',
        dataKey: 'vs LYMTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([MTD]/[MTD Comp Period]) - 1',
        dataKey: 'vs LYMTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD Mix]',
        dataKey: 'MTD Mix',
        qLabel: 'MTD Mix',
      },
      {
        qField: '=[MTD Mix] - [MTD Comparable Mix]',
        dataKey: 'vsMTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD]',
        dataKey: 'YTD',
        qLabel: 'YTD',
      },
      {
        qField: '=[YTD]-[YTD Comp Period]',
        dataKey: 'vs LYYTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([YTD]/[YTD Comp Period]) - 1',
        dataKey: 'vs LYYTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD Mix]',
        dataKey: 'YTD Mix',
        qLabel: 'YTD Mix',
      },
      {
        qField: '=[YTD Mix] - [YTD Comparable Mix]',
        dataKey: 'vsYTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: "=if(metric='Volume',0,1)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: "=if(variance='Last Year','vs LY','vs LLY')",
        dataKey: 'varianceText',
        qLabel: 'varianceText',
      },
      {
        qField: '=[WTD UPT]',
        dataKey: 'WTDUPT',
        qLabel: 'WTD UPT',
      },
      {
        qField: '=[WTD UPT] - [WTD UPT Comparable]',
        dataKey: 'vsLYWTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[WTD AVT]',
        dataKey: 'WTDAVT',
        qLabel: 'WTD AVT',
      },
      {
        qField: '=[WTD AVT]-[WTD AVT Comparable]',
        dataKey: 'vsLYWTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD UPT]',
        dataKey: 'MTDUPT',
        qLabel: 'MTD UPT',
      },
      {
        qField: '=[MTD UPT] - [MTD UPT Comparable]',
        dataKey: 'vsLYMTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD AVT]',
        dataKey: 'MTDAVT',
        qLabel: 'MTD AVT',
      },
      {
        qField: '=[MTD AVT]-[MTD AVT Comparable]',
        dataKey: 'vsLYMTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD UPT]',
        dataKey: 'YTDUPT',
        qLabel: 'YTD UPT',
      },
      {
        qField: '=[YTD UPT] - [YTD UPT Comparable]',
        dataKey: 'vsLYYTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD AVT]',
        dataKey: 'YTDAVT',
        qLabel: 'YTD AVT',
      },
      {
        qField: '=[YTD AVT]-[YTD AVT Comparable]',
        dataKey: 'vsLYYTDAVT',
        qLabel: 'vs LY',
      },
    ],
  },
  {
    type: 'table',
    title: 'Sales by Product',
    md: 12,
    xs: 12,
    qPage: { qWidth: 30, qHeight: 333 },
    initialSortKey: 'WTD',
    initialColumnsToExclude: [
      'metric',
      'varianceText',
      'WTD Mix',
      'vsWTDPP',
      'MTD Mix',
      'vsMTDPP',
      'YTD Mix',
      'vsYTDPP',
      'WTDAVT',
      'vsLYWTDAVT',
      'MTDAVT',
      'vsLYMTDAVT',
      'YTDAVT',
      'vsLYYTDAVT',
      'YTDUPT',
      'vsLYYTDUPT',
      'MTDUPT',
      'vsLYWTDUPT',
      'WTDUPT',
      'vsLYMTDUPT',
    ],
    options: {
      coreColumns: ['Product'],
      alwaysHide: ['metric', 'varianceText'],
      dropdown: [
        {
          name: 'Actual',
          hideColumns: [
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'vsLYMTDUPT',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
        {
          name: 'Mix',
          hideColumns: [
            'WTDAVT',
            'vsLYMTDUPT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'vsLYMTDUPT',
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
          ],
        },
        {
          name: 'Tickets',
          hideColumns: [
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
      ],
    },
    cols: [
      {
        qField: [
          "=[product_id] & 'SPLIT' & Capitalize([style_desc])  & 'SPLIT' & Capitalize([color_desc]) & 'SPLIT' & [URL]",
        ],
        dataKey: 'Product',
        qLabel: 'Product',
        qGrouping: 'H',
      },
      {
        qField: '=WTD',
        dataKey: 'WTD',
        qLabel: 'WTD',
      },
      {
        qField: '=[WTD]-[WTD Comp Period]',
        dataKey: 'vs LYWTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([WTD]/[WTD Comp Period]) - 1',
        dataKey: 'vs LYWTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[WTD Mix]',
        dataKey: 'WTD Mix',
        qLabel: 'WTD Mix',
      },
      {
        qField: '=[WTD Mix] - [WTD Comparable Mix]',
        dataKey: 'vsWTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD]',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },

      {
        qField: '=[MTD]-[MTD Comp Period]',
        dataKey: 'vs LYMTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([MTD]/[MTD Comp Period]) - 1',
        dataKey: 'vs LYMTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD Mix]',
        dataKey: 'MTD Mix',
        qLabel: 'MTD Mix',
      },
      {
        qField: '=[MTD Mix] - [MTD Comparable Mix]',
        dataKey: 'vsMTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD]',
        dataKey: 'YTD',
        qLabel: 'YTD',
      },
      {
        qField: '=[YTD]-[YTD Comp Period]',
        dataKey: 'vs LYYTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([YTD]/[YTD Comp Period]) - 1',
        dataKey: 'vs LYYTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD Mix]',
        dataKey: 'YTD Mix',
        qLabel: 'YTD Mix',
      },
      {
        qField: '=[YTD Mix] - [YTD Comparable Mix]',
        dataKey: 'vsYTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: "=if(metric='Volume',0,1)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: "=if(variance='Last Year','vs LY','vs LLY')",
        dataKey: 'varianceText',
        qLabel: 'varianceText',
      },
      {
        qField: '=[WTD UPT]',
        dataKey: 'WTDUPT',
        qLabel: 'WTD UPT',
      },
      {
        qField: '=[WTD UPT] - [WTD UPT Comparable]',
        dataKey: 'vsLYWTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[WTD AVT]',
        dataKey: 'WTDAVT',
        qLabel: 'WTD AVT',
      },
      {
        qField: '=[WTD AVT]-[WTD AVT Comparable]',
        dataKey: 'vsLYWTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD UPT]',
        dataKey: 'MTDUPT',
        qLabel: 'MTD UPT',
      },
      {
        qField: '=[MTD UPT] - [MTD UPT Comparable]',
        dataKey: 'vsLYMTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD AVT]',
        dataKey: 'MTDAVT',
        qLabel: 'MTD AVT',
      },
      {
        qField: '=[MTD AVT]-[MTD AVT Comparable]',
        dataKey: 'vsLYMTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD UPT]',
        dataKey: 'YTDUPT',
        qLabel: 'YTD UPT',
      },
      {
        qField: '=[YTD UPT] - [YTD UPT Comparable]',
        dataKey: 'vsLYYTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD AVT]',
        dataKey: 'YTDAVT',
        qLabel: 'YTD AVT',
      },
      {
        qField: '=[YTD AVT]-[YTD AVT Comparable]',
        dataKey: 'vsLYYTDAVT',
        qLabel: 'vs LY',
      },
    ],
  },
  {
    type: 'table',
    title: 'Sales by Location',
    md: 12,
    xs: 12,
    qPage: { qWidth: 30, qHeight: 333 },
    initialSortKey: 'WTD',
    initialColumnsToExclude: [
      'metric',
      'varianceText',
      'WTD Mix',
      'vsWTDPP',
      'MTD Mix',
      'vsMTDPP',
      'YTD Mix',
      'vsYTDPP',
      'WTDAVT',
      'vsLYWTDAVT',
      'MTDAVT',
      'vsLYMTDAVT',
      'YTDAVT',
      'vsLYYTDAVT',
      'YTDUPT',
      'vsLYYTDUPT',
      'MTDUPT',
      'vsLYWTDUPT',
      'WTDUPT',
      'vsLYMTDUPT',
    ],
    options: {
      coreColumns: ['department_desc'],
      alwaysHide: ['metric', 'varianceText'],
      dropdown: [
        {
          name: 'Actual',
          hideColumns: [
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'vsLYMTDUPT',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
        {
          name: 'Mix',
          hideColumns: [
            'vsLYMTDUPT',
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'vsLYMTDUPT',
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
          ],
        },
        {
          name: 'Tickets',
          hideColumns: [
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
      ],
    },
    cols: [
      {
        qField: ['[location_desc]'],
        dataKey: 'location_desc',
        qLabel: 'location_desc',
        qGrouping: 'H',
      },
      {
        qField: '=WTD',
        dataKey: 'WTD',
        qLabel: 'WTD',
      },
      {
        qField: '=[WTD]-[WTD Comp Period]',
        dataKey: 'vs LYWTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([WTD]/[WTD Comp Period]) - 1',
        dataKey: 'vs LYWTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[WTD Mix]',
        dataKey: 'WTD Mix',
        qLabel: 'WTD Mix',
      },
      {
        qField: '=[WTD Mix] - [WTD Comparable Mix]',
        dataKey: 'vsWTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD]',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },

      {
        qField: '=[MTD]-[MTD Comp Period]',
        dataKey: 'vs LYMTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([MTD]/[MTD Comp Period]) - 1',
        dataKey: 'vs LYMTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD Mix]',
        dataKey: 'MTD Mix',
        qLabel: 'MTD Mix',
      },
      {
        qField: '=[MTD Mix] - [MTD Comparable Mix]',
        dataKey: 'vsMTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD]',
        dataKey: 'YTD',
        qLabel: 'YTD',
      },
      {
        qField: '=[YTD]-[YTD Comp Period]',
        dataKey: 'vs LYYTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([YTD]/[YTD Comp Period]) - 1',
        dataKey: 'vs LYYTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD Mix]',
        dataKey: 'YTD Mix',
        qLabel: 'YTD Mix',
      },
      {
        qField: '=[YTD Mix] - [YTD Comparable Mix]',
        dataKey: 'vsYTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: "=if(metric='Volume',0,1)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: "=if(variance='Last Year','vs LY','vs LLY')",
        dataKey: 'varianceText',
        qLabel: 'varianceText',
      },
      {
        qField: '=[WTD UPT]',
        dataKey: 'WTDUPT',
        qLabel: 'WTD UPT',
      },
      {
        qField: '=[WTD UPT] - [WTD UPT Comparable]',
        dataKey: 'vsLYWTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[WTD AVT]',
        dataKey: 'WTDAVT',
        qLabel: 'WTD AVT',
      },
      {
        qField: '=[WTD AVT]-[WTD AVT Comparable]',
        dataKey: 'vsLYWTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD UPT]',
        dataKey: 'MTDUPT',
        qLabel: 'MTD UPT',
      },
      {
        qField: '=[MTD UPT] - [MTD UPT Comparable]',
        dataKey: 'vsLYMTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD AVT]',
        dataKey: 'MTDAVT',
        qLabel: 'MTD AVT',
      },
      {
        qField: '=[MTD AVT]-[MTD AVT Comparable]',
        dataKey: 'vsLYMTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD UPT]',
        dataKey: 'YTDUPT',
        qLabel: 'YTD UPT',
      },
      {
        qField: '=[YTD UPT] - [YTD UPT Comparable]',
        dataKey: 'vsLYYTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD AVT]',
        dataKey: 'YTDAVT',
        qLabel: 'YTD AVT',
      },
      {
        qField: '=[YTD AVT]-[YTD AVT Comparable]',
        dataKey: 'vsLYYTDAVT',
        qLabel: 'vs LY',
      },
    ],
  },
];

export const filters = [
  {
    title: 'metric',
    dimension: 'metric',
    icon: 'eva:pricetags-fill',
    lock: true,
  },
  {
    title: 'Comparison',
    dimension: 'variance',
    icon: 'eva:calendar-fill',
    lock: true,
  },
  {
    title: 'As Of',
    dimension: '[AsOfWeek]',
    icon: 'bx:bxs-calendar-edit',
    singleSelect: true,
  },
  {
    title: 'Location',
    dimension: '[location_desc]',
    icon: 'eva:pin-fill',
  },
  {
    title: 'Department',
    dimension: '[department_desc]',
    icon: 'mdi:tshirt-crew',
  },
];

export const bestsellers = [
  {
    type: 'bestseller',
    title: 'bestseller',
    qPage: { qWidth: 15, qHeight: 52 },
    sortColumn: 4,
    cols: [
      {
        qField: '[product_id]',
        dataKey: 'ProdCode',
        qLabel: 'ProdCode',
      },
      {
        qField: '=[style_desc]',
        dataKey: 'ProdDsc',
        qLabel: 'ProdDsc',
      },
      {
        qField: '=[color_desc]',
        dataKey: 'ProdColor',
        qLabel: 'ProdColor',
      },
      {
        qField: '=[URL]',
        dataKey: 'URL',
        qLabel: 'URL',
      },
      {
        // eslint-disable-next-line
        qField: '=sum(${ValueVolumeMetric})',
        dataKey: 'Sales',
        qLabel: 'Sales',
      },
      {
        // eslint-disable-next-line
        qField: '=sum(sales_value)',
        dataKey: 'SalesValue',
        qLabel: 'SalesValue',
      },
      {
        // eslint-disable-next-line
        qField: '=sum(sales_qty)',
        dataKey: 'SalesVolume',
        qLabel: 'SalesVolume',
      },
    ],
  },
];
