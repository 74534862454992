import { useState, useRef, useEffect } from 'react';

import { addDays } from 'date-fns';
import { useButton } from '@motor-js/engine';

// material
import { Button, Card, Box } from '@mui/material';

// components
import { DateRangePicker } from 'react-date-range';
import PropTypes from 'prop-types';
import { fDateToNumber } from '../../utils/formatDate';

Calendar.propTypes = {
  title: PropTypes.string,
};

function Calendar({ title }) {
  const [showCalendar, setShowCalendar] = useState(false);
  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setShowCalendar(false);
    }
  };

  const { selectValues } = useButton();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const [selectedRange, setSelectedRange] = useState({
    startDate: addDays(new Date(), -1),
    endDate: addDays(new Date(), -1),
    key: 'selection',
  });

  const handleSelect = (date) => {
    setSelectedRange(date.selection);

    const { startDate, endDate } = date.selection;

    const dateArray = Array(fDateToNumber(endDate) - fDateToNumber(startDate) + 1)
      .fill()
      .map((_, idx) => fDateToNumber(startDate) + idx);

    selectValues(dateArray, '[date]', false, true);
  };

  const maxDate = addDays(new Date(), -1);

  return (
    <>
      <Box
        ref={myRef}
        sx={{
          mt: 2,
          zIndex: '99',
        }}
      >
        <Box
          sx={{
            display: 'grid',
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setShowCalendar(!showCalendar);
            }}
            sx={{ mr: 3 }}
          >
            {title}
          </Button>
        </Box>
        {showCalendar ? (
          <Card
            sx={{
              width: 'fit-content',
              position: 'absolute',
              marginTop: '10px',
            }}
          >
            <DateRangePicker ranges={[selectedRange]} onChange={handleSelect} maxDate={maxDate} />
          </Card>
        ) : null}
      </Box>
    </>
  );
}

export default Calendar;
