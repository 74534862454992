import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, ModalUnstyled, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTable, useButton } from '@motor-js/engine';
import { styled } from '@mui/material/styles';

// components
import { SkeletonProductItem } from '../skeleton';
//
import Card from './Card';
import Table from './Table';
import Calendar from './Calendar';
// ----------------------------------------------------------------------

CardGroup.propTypes = {
  config: PropTypes.object.isRequired,
};

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: '75vw',
  bgcolor: 'background.paper',
  borderRadius: '7px',
  p: 2,
  px: 4,
  pb: 3,
};

export default function CardGroup({ config }) {
  const { cols, qPage, sortColumn } = config;
  const sortCriteria = { qInterColumnSortOrder: [sortColumn] };
  const [rankOrder, setRankOrder] = React.useState('descending');

  const [open, setOpen] = React.useState(false);
  const [selectedProd, setSelectedProd] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { select, selectValues } = useButton();

  const handleModalOpen = (ProdCode) => {
    const { text } = ProdCode;
    handleOpen();
    select(text, '[product_id]');
    setSelectedProd(text);
  };

  const handleModalClose = () => {
    handleClose();
    selectValues([selectedProd], '[product_id]', true);
  };

  const { dataSet, handleSortChange, headerGroup } = useTable({
    cols,
    qPage,
    sortCriteria,
  });

  const handleOrderChange = (e) => {
    setRankOrder(e.target.value);
    handleSortChange(headerGroup[6]);
  };

  return (
    <>
      <Box sx={{ display: 'flex', mb: 4 }}>
        <Calendar title="select range" marginLeft={0} />
        <FormControl sx={{ width: '130px', backgroundColor: 'white' }}>
          <InputLabel id="demo-simple-select-label">Rank sales by</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="sales"
            value={rankOrder}
            onChange={handleOrderChange}
          >
            <MenuItem value={'ascending'}>Ascending</MenuItem>
            <MenuItem value={'descending'}>Descending</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gap: 3,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
          },
        }}
        id="bestsellers"
      >
        {(dataSet || [...Array(12)]).map((product, index) =>
          product ? (
            <Card key={index} product={product} handleModalOpen={handleModalOpen} />
          ) : (
            <SkeletonProductItem key={index} />
          )
        )}
      </Box>

      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleModalClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Table
            title={'Sales by Location'}
            cols={[
              {
                qField: '[location_desc]',
                dataKey: 'Location',
                qLabel: 'Location',
              },
              {
                qField: "=Date(min({$<date= >} date),'DD MMM YYYY')",
                dataKey: 'First Sale',
                qLabel: 'First Sale',
              },
              {
                // eslint-disable-next-line
                qField: '=sum(sales_value)',
                dataKey: 'Sales Value',
                qLabel: 'Sales Value',
              },
              {
                // eslint-disable-next-line
                qField: '=sum(sales_qty)',
                dataKey: 'Sales Volume',
                qLabel: 'Sales Volume',
              },
            ]}
            qPage={{ qWidth: 15, qHeight: 600 }}
            initialSortKey={'Sales Value'}
            initialColumnsToExclude={['varianceText']}
            disableVariance
            disableMetric
          />
        </Box>
      </StyledModal>
    </>
  );
}
